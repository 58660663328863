export const AppSetting = {
    baseUrl: "https://hincaretb.com/api/",

    timeoutValue: 60000,

    centerUUId: "263cc549-274b-4c17-92f1-94d79b1947ff",
    orgName: "時間銀行 · 生活助手",

    firebaseConfig: {
        apiKey: "AIzaSyCtkMfRz36yUkXzu-UP_pgKLKpbbotfAmg",
        authDomain: "timebank-csfc.firebaseapp.com",
        databaseURL: "https://timebank-csfc.firebaseio.com",
        projectId: "timebank-csfc",
        storageBucket: "timebank-csfc.appspot.com",
        messagingSenderId: "574410185999",
        appId: "1:574410185999:web:a2b317bef687a589a4727a",
        measurementId: "G-TVW511Q8FL"
    },

    tokenKey: "timebank_token",
    roleKey: "timebank_roles",
    remainDays: 14
}