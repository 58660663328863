<div class="fadeIn" *ngIf="product">
    <div class="flex-row align-items-center">
        <p translate>獎勵概覽</p>
        <p class="text-grey ml-1 mr-1">/</p>
        <p class="text-grey" translate>獎勵詳情</p>
    </div>

    <h1 class="mt-3" translate>參與用戶</h1>

    <ng-container *ngTemplateOutlet="product_detail"></ng-container>

    <ng-container *ngTemplateOutlet="management"></ng-container>
</div>


<ng-template #product_detail>
    <div class="container mt-4">
        <div class="header">
            <p class="flex-1" translate>{{ '記錄編號：' | translate: {value: product.id} }}</p>
            <p translate> {{ '創建時間：' | translate: { value: product.createdAt | date: 'yyyy-MM-dd HH:mm'} }}</p>
        </div>

        <div class="body">
            <div class="inline-flex-row align-items-center">
                <div class="input-row first">
                    <p class="title required" matTooltip="{{ '獎勵標題' | translate }}" [matTooltipPosition]="'above'" translate>獎勵標題</p>
                    <p>{{ product.productName }}</p>
                </div>
                <div class="input-row second">
                    <p class="title" matTooltip="{{ '項目狀態' | translate }}" [matTooltipPosition]="'above'" translate>項目狀態</p>
                    <ng-container *ngIf="product.active">
                        <p class="status yellow text-sm mr-2 align-self-center" *ngIf="!product.isLaunched" translate>未發布</p>
                        <p class="status green text-sm mr-2 align-self-center" *ngIf="product.isLaunched && !product.isClosed" translate>進行中</p>
                        <p class="status red text-sm mr-2 align-self-center" *ngIf="product.isOutOfStock" translate>庫存不足</p>
                        <p class="status red text-sm mr-2 align-self-center" *ngIf="product.isPending" translate>等待抽籤</p>
                        <p class="status grey text-sm mr-2 align-self-center" *ngIf="product.isClosed" translate>已結束</p>
                    </ng-container>

                    <p class="status grey text-sm mr-2 align-self-center" *ngIf="!product.active" translate>已下架</p>
                    <p class="status blue text-sm align-self-center" *ngIf="product.isEdited" translate>已修改</p>
                </div>
            </div>

            <div class="inline-flex-row align-items-center">
                <div class="input-row first">
                    <p class="title required" matTooltip="{{ '參與方式' | translate }}" [matTooltipPosition]="'above'" translate>參與方式</p>
                    <p *ngIf="product.format == 'FirstComeFirstServed'" translate>先到先得</p>
                    <p *ngIf="product.format == 'StaffOpting'" translate>職員處理</p>
                </div>
                <div class="input-row second">
                    <p class="title required" matTooltip="{{ '兌換比率' | translate }}" [matTooltipPosition]="'above'" translate>兌換比率</p>
                    <p translate>{{ '單個獎勵須扣除時分' | translate: { value: product.price } }} </p>
                </div>
            </div>

            <div class="inline-flex-row align-items-center">
                <div class="input-row first">
                    <p class="title required" matTooltip="{{ '截止換領' | translate }}" [matTooltipPosition]="'above'" translate>截止換領</p>
                    <p>{{ product.closeDate | date : 'longDate' : '' : lang }} {{ product.closeDate | date : 'HH:mm' }}</p>
                </div>
                <div class="input-row second">
                    <p class="title required" matTooltip="{{ '換領次限' | translate }}" [matTooltipPosition]="'above'" translate>換領次限</p>
                    <p *ngIf="product.maximumQuantityPerPerson != -1" translate>{{ '每個用戶最多可換領數量件' | translate: {value: product.maximumQuantityPerPerson} }}</p>
                    <p *ngIf="product.maximumQuantityPerPerson == -1" translate>不限次限</p>
                </div>
            </div>

            <div class="flex-row align-items-center">
                <div class="input-row first">
                    <p class="title required" translate>庫存</p>
                    <ng-container *ngIf="product.stock != -1">
                        <p translate>剩餘</p>
                        <p class="text-lightblue ml-2 mr-2">{{ product.stock }}</p>
                        <p translate>{{ '/件' | translate: {value: totalStock} }}</p>
                    </ng-container>
                    <p *ngIf="product.stock == -1" translate>庫存充足</p>
                </div>
            </div>
        </div>

        <div class="inline-flex-row justify-content-center">
            <button class="button outline" (click)="back()" translate>返回</button>
        </div>
    </div>
</ng-template>


<ng-template #management>
    <div class="management-container">
        <div class="management-container-header">
            <p class="management-container-header-item" [ngClass]="{active: tab == 1}" (click)="tab = 1">{{ '已兌換' | translate: {value: redeemedUsers.length} }}</p>
            <p class="management-container-header-item" [ngClass]="{active: tab == 2}" (click)="tab = 2">{{ '等候處理' | translate: {value: pendingUsers.length} }}</p>
        </div>

        <div class="management-container-body">
            <div class="management-container-body-slide-container" [ngClass]="{tab1: tab == 1, tab2: tab == 2}">
                <div class="management-container-body-slide">
                    <app-product-management-redeem [redeems]="redeemedUsers" [product]="product" (refresh)="fetch(false)"></app-product-management-redeem>
                </div>
                <div class="management-container-body-slide">
                    <app-product-management-pending [redeems]="pendingUsers" [product]="product" (refresh)="fetch(false)"></app-product-management-pending>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-redeem-grant-popup [user]="user" [product]="product" (refresh)="fetch(false)"></app-redeem-grant-popup>