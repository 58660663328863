import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { PopupMessages } from 'src/app/backend/popup-message';
import { CellGroup } from 'src/app/models/cell-groups/cell-group';
import { Center } from 'src/app/models/centers/center';
import { Health } from 'src/app/models/healths/health';
import { Agent } from 'src/app/models/profiles/agent';
import { Profile } from 'src/app/models/profiles/profile';
import { UpdateUserModel } from 'src/app/models/profiles/update-user-model';
import { Service } from 'src/app/models/services/service';
import { ServiceType } from 'src/app/models/services/service-type';
import { AgentService } from 'src/app/services/agent/agent.service';
import { AuthenticationService } from 'src/app/services/authenticate/authentication.service';
import { CenterService } from 'src/app/services/center/center.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PopupMessageService } from 'src/app/services/popup-message/popup-message.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ServiceService } from 'src/app/services/service/service.service';
import { AvailabilityTableComponent } from '../availability-table/availability-table.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @ViewChild('availability') availabilityComponent: AvailabilityTableComponent | undefined;

  lang: string = this.translateService.currentLang;
  profile: Profile | null = null;
  serviceTypes: ServiceType[] = [];
  centers: Center[] = [];

  index: number = 0;

  showSuccessMessage = false;
  readonly: boolean = true;

  constructor(
    private profileService: ProfileService,
    private serviceService: ServiceService,
    private centerService: CenterService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private authService: AuthenticationService,
    private agentService: AgentService,
    private popupMessageService: PopupMessageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.fetch();
    this.translateService.onLangChange.subscribe(() => this.lang = this.translateService.currentLang);
    this.route.queryParams.subscribe(value => {
      this.readonly = value.readonly == "false" ? false : true;
    });
  }

  fetch(): void {
    this.loadingService.startLoading();
    forkJoin({
      profile: this.profileService.getObservable,
      centers: this.centerService.getCenters(),
      services: this.serviceService.getServiceTypeList()
    })
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.profile = value.profile;
          this.centers = value.centers;
          this.serviceTypes = value.services;
          this.serviceTypes.forEach(st => st.services.filter(s => this.profile?.services.some(service => s.uuId == service.uuId))
            .forEach(s => s.selected = true));
          this.profileService.agentSelections?.subscribe(value => {
            if (value.uuid == this.profile?.uuId)
              (this.profile as Profile).form.agents = (value.agents as Agent[]);
          });
        }
      });
  }

  cellGroupPopupAction = ((cellGroups: CellGroup[]) => {
    (this.profile as Profile).form.cellGroups = cellGroups;
  }).bind(this);

  dropAddressAction = ((index: number) => {
    this.profile?.form.addresses.splice(index, 1);
  }).bind(this);

  dropAgent(agent: Agent, index: number): void {
    if (agent.isOriginal == false)
      this.profile?.form.agents.splice(index, 1);
    else {
      this.loadingService.startLoading();
      this.agentService.removeAgent({ AssigneeUUId: this.profile?.uuId as string, AgentUUId: agent.uuId })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe({
          next: value => this.profile?.form.agents.splice(this.profile.form.agents.findIndex(a => a.uuId == agent.uuId), 1)
        });
    }
  }

  selectAgents(): void {
    this.profileService.agentSelections = of({ uuid: this.profile?.uuId, agents: this.profile?.form.agents });
    this.router.navigate(['/main/user/overview', this.profile?.uuId, 'agent']);
  }

  servicePopupAction = ((services: Service[]) => {
    (this.profile as Profile).form.services = services;
    this.serviceTypes.forEach(st => st.services.forEach(s => {
      s.selected = services.some(service => s.uuId == service.uuId);
    }));
    this.index = 0;
  }).bind(this);


  submit(): void {
    console.log("this.profile?.remark",this.profile?.remark);
    if (this.profile?.form.valid == false) {
      this.profile.form.formGroup.markAllAsTouched();
      this.profile.form.addresses.forEach(address => address.form.formGroup.markAllAsTouched());
      // this.messageService.displayPopupMessage(AppStaticMessage.InvalidInformationMessage);
      return;
    }

    (this.profile as Profile).form.availabilities = (this.availabilityComponent as AvailabilityTableComponent).getModels();

    let observable: Observable<any>;

    if ((this.profile as Profile).form.doChangeUsernameAndPassword())
      observable = forkJoin({
        editLogin: this.authService.changeUsernameAndPassword({
          userUUId: this.profile?.uuId as string,
          username: this.username?.value,
          password: this.password?.value
        }),
        editProfile: this.profileService.updateProfile(this.profile?.uuId as string, this.profile?.form.getUpdateUserModel() as UpdateUserModel)
      });
    else
      observable = this.profileService.updateProfile(this.profile?.uuId as string, this.profile?.form.getUpdateUserModel() as UpdateUserModel);

    this.loadingService.startLoading();

    let agentObservables = this.profile?.form.agents.filter(agent => agent.isOriginal == false).map(agent => this.agentService.assignAgent({
      AssigneeUUId: this.profile?.uuId as string,
      AgentUUId: agent.uuId,
      Remark: agent.remark.value
    })) as Observable<any>[];

    if (agentObservables.length > 0)
      observable = observable.pipe(switchMap(() => forkJoin(agentObservables)))

    observable
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          // this.showSuccessMessage = true;
          this.readonly = true;
          forkJoin(
            {
              text: this.translateService.get("資料已保存"),
              action: this.translateService.get("關閉")
            }
          ).subscribe(value => {
            this.snackBar.open(value.text, value.action, { horizontalPosition: 'right', duration: 5000 });
          });
          window.scrollTo(0, 0)
          this.fetchProfile();
        }
      });
  }

  fetchProfile(): void {
    this.loadingService.startLoading();
    this.profileService.getProfileByUUId(this.profile?.uuId as string)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => {
          this.profile = value;
          this.profileService.agentSelections?.subscribe(value => {
            if (value.uuid == this.profile?.uuId)
              (this.profile as Profile).form.agents = (value.agents as Agent[]);
          });
        }
      });
  }

  public forcedSignOut(): void {
    this.loadingService.startLoading();
    this.authService.forcedSignOut(this.profile?.uuId as string)
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe({
        next: value => this.popupMessageService.messageSignal.emit(PopupMessages.ForcedSignOutSuccessMessage)
        // error: value => this.popupMessageService.messageSignal.emit(PopupMessages.ForcedSignOutFailMessage),
      });
  }

  healthPopupAction = ((healths: Health[]) => {
    if (this.profile) {
      this.profile.form.healths = healths;
    }
  }).bind(this);

  deleteUser(): void {
    var subscriber = this.popupMessageService.executeSuccessSignal.subscribe(value => {
      subscriber.unsubscribe();
      var sub = this.popupMessageService.executeSuccessSignal.subscribe(value => {
        sub.unsubscribe();
      });
      var ob = new Observable(subscriber => {
        this.router.navigate(['/main/user/overview']);
      });
      this.popupMessageService.messageSignal.emit(PopupMessages.UserDeletedMessage(ob))
    });

    this.popupMessageService.messageSignal.emit(PopupMessages.DeleteUserMessage(this.profileService.deleteUsers([this.profile?.uuId as string])));
  }

  get selectedServiceTypes(): ServiceType[] {
    return this.serviceTypes.filter(st => st.services.some(s => s.selected == true));
  }

  get username(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['username'];
  }

  get password(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['password'];
  }

  get center(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['center'];
  }

  get chineseName(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['chineseName'];
  }

  get englishName(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['englishName'];
  }

  get gender(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['gender'];
  }

  get phone(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['phone'];
  }

  get birthday(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['birthday'];
  }

  get age(): number | null {
    return this.birthday?.valid ? new Date(Date.now() - (this.birthday.value as Date).getTime()).getUTCFullYear() - 1970 : null;
  }

  get simCard(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['simCard'];
  }

  get literacy(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['literacy'];
  }

  get educationLevel(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['educationLevel'];
  }

  get contactPersonName(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['contactPersonName'];
  }

  get contactPersonPhone(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['contactPersonPhone'];
  }


  get remark(): AbstractControl | undefined {
    return this.profile?.form.formGroup.controls['remark'];
  }
}
