import { FormControl, Validators } from "@angular/forms";
import { RedeemExportModel } from "../exports/redeem-export-model";

export interface IRedeem {
    memberId: string;
    chineseName: string;
    centerCode: string;
    gender: string;
    dateOfBirth: Date;
    englishName: string;
    redeemCode: string;
    redeemReason: string;
    timeBalance: number;
    uuId: string;
    phone: string;
    redeemDate: Date;
    quantity: number;
    redeemState: string;
    price: number;
    userUUId: string;
    deliveredDate: string;
}

export class Redeem {
    selected: boolean;
    memberId: string;
    chineseName: string;
    centerCode: string;
    gender: string;
    dateOfBirth: Date;
    englishName: string;
    redeemCode: string;
    redeemReason: any;
    timeBalance: number;
    uuId: string;
    phone: string;
    redeemDate: Date;
    quantity: number;
    redeemState: string;
    price: number;
    userUUId: string;
    editing: boolean;
    quantityInput: FormControl;
    deliveredDate: Date;

    constructor(redeem: IRedeem) {
        this.memberId = redeem.memberId;
        this.chineseName = redeem.chineseName;
        this.centerCode = redeem.centerCode;
        this.gender = redeem.gender;
        this.dateOfBirth = typeof (redeem.dateOfBirth) == "string" ? new Date(redeem.dateOfBirth + 'Z') : redeem.dateOfBirth;
        this.englishName = redeem.englishName;
        this.redeemCode = redeem.redeemCode;
        this.redeemDate = typeof (redeem.redeemDate) == "string" ? new Date(redeem.redeemDate + 'Z') : redeem.redeemDate;
        this.redeemReason = redeem.redeemReason == null ? null : JSON.parse(redeem.redeemReason);
        this.timeBalance = redeem.timeBalance;
        this.uuId = redeem.uuId;
        this.phone = redeem.phone;
        this.quantity = redeem.quantity;
        this.selected = false;
        this.redeemState = redeem.redeemState;
        this.price = redeem.price;
        this.userUUId = redeem.uuId;
        this.editing = false;
        this.quantityInput = new FormControl(this.quantity, [Validators.required, Validators.min(1)]);
        this.deliveredDate=typeof (redeem.deliveredDate) == "string" ? new Date(redeem.deliveredDate + 'Z') : redeem.deliveredDate;
    }

    getExportModel(): RedeemExportModel {
        return {
            "會員編號": this.memberId,
            "中文姓名": this.chineseName,
            "英文姓名": this.englishName,
            "中心編號": this.centerCode,
            "性別": this.gender == "M" ? "男" : this.gender == "F" ? "女" : "",
            "年齡": this.age?.toString(),
            "電話": this.phone,
            "換領數量": this.quantity.toString(),
            "交易時分": this.price.toString(),
            "換領編碼": this.redeemCode,
            "換領時間": this.redeemDate==null?"-": this.redeemDate.toJSON().split('T')[0] + " " + this.redeemDate.toTimeString().substr(0, 5),            
            "狀態": this.redeemState == "Redeemed" ? "已兌換" :  this.redeemState == "Delivered" ?"已領取":"等候處理",
            "問題1": typeof this.redeemReason!="undefined" ? this.redeemReason[0]?.question : null,
            "答案1": typeof this.redeemReason.answer!="undefined" ?( typeof (this.redeemReason[0]?.answer) == "string" ? this.redeemReason[0]?.answer : this.redeemReason[0]?.answer.join(", ")) : null,
            "問題2":  typeof this.redeemReason!="undefined" ? this.redeemReason[1]?.question : null,
            "答案2":  typeof this.redeemReason.answer!="undefined" ? (typeof (this.redeemReason[1]?.answer) == "string" ? this.redeemReason[1]?.answer : this.redeemReason[1]?.answer.join(", ")) : null,
            "問題3":  typeof this.redeemReason!="undefined" ? this.redeemReason[2]?.question : null,
            "答案3":  typeof this.redeemReason.answer!="undefined" ?( typeof (this.redeemReason[2]?.answer) == "string" ? this.redeemReason[2]?.answer : this.redeemReason[2]?.answer.join(", ")) : null,
            "備註":""
        }
    }

    // public toJsonFormat(): any {
    //     return {
    //         "會員編號": this.memberId,
    //         "中文姓名": this.chineseName,
    //         "英文姓名": this.englishName,
    //         "中心編號": this.centerCode,
    //         "性別": this.gender == "M" ? "男" : "女",
    //         "年齡": this.age,
    //         "電話": this.phone,
    //         "換領數量": this.quantity,
    //         "交易時分": this.price,
    //         "換領編碼": this.redeemCode,
    //         "換領時間": this.redeemDate.toISOString().substr(0, 10),
    //         "問題1": this.redeemReason[0]?.question,
    //         "答案1": typeof (this.redeemReason[0]?.answer) == "string" ? this.redeemReason[0]?.answer : this.redeemReason[0]?.answer.join(", "),
    //         "問題2": this.redeemReason[1]?.question,
    //         "答案2": typeof (this.redeemReason[1]?.answer) == "string" ? this.redeemReason[1]?.answer : this.redeemReason[1]?.answer.join(", "),
    //         "問題3": this.redeemReason[2]?.question,
    //         "答案3": typeof (this.redeemReason[2]?.answer) == "string" ? this.redeemReason[2]?.answer : this.redeemReason[2]?.answer.join(", "),
    //         "備註": ""
    //     }
    // }

    get age(): number | null {
        return this.dateOfBirth ? new Date(Date.now() - (this.dateOfBirth as Date).getTime()).getUTCFullYear() - 1970 : null;
    }

    get hasEnoughBalance(): boolean {
        return this.timeBalance >= this.price;
    }
}