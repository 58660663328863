import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { Profile } from 'src/app/models/profiles/profile';
import { Product } from 'src/app/models/redeems/product';
import { Redeem } from 'src/app/models/redeems/redeem';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ProductService } from 'src/app/services/product/product.service';
import { RedeemService } from 'src/app/services/redeem/redeem.service';

interface IRedeemInfo{
  UUId:string;
  RedeemState:String;
}
 interface  IProductInfo{
  stock:Number;
  redeems:IRedeemInfo[];
}
 class RedeemInfo{
  UUId:string;
  RedeemState:String;
  constructor(redeemInfo: IRedeemInfo) {
    this.UUId=redeemInfo.UUId;
    this.RedeemState=redeemInfo.RedeemState;
  }
}
 class  ProductInfo{
  stock:Number;
  redeems:RedeemInfo[];
  constructor(redeemInfo: IProductInfo) {
    this.stock=redeemInfo.stock;
    this.redeems=redeemInfo.redeems;
  }
}

@Component({
  selector: 'app-redeem-management-page',
  templateUrl: './redeem-management-page.component.html',
  styleUrls: ['./redeem-management-page.component.scss']
})

export class RedeemManagementPageComponent implements OnInit {

  lang: string = this.translateService.currentLang;
  tab: number = 1;
  product!: Product;

   
  productInfo?:ProductInfo;

  constructor(
    private productService: ProductService,
    private loadingService: LoadingService,
    private redeemService: RedeemService,
    private route: ActivatedRoute,
    private location: Location,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(value => {
      this.lang = value.lang;
    });
    setInterval(()=>{
      this.fetch(true);
    },10000)
    this.fetch(false);
  }

  fetch(noLoading:boolean): void {
    if(!noLoading)
    this.loadingService.startLoading();
    this.route.params.subscribe(value => {
      this.productService.getProduct(value.id)
        .pipe(finalize(() => !noLoading?null:this.loadingService.stopLoading()))
        .subscribe({
          next: value => {
            const temp:ProductInfo ={
              stock:value.stock,
              redeems:[]
            };
            value.redeems.forEach(redeem=>{
              const info:RedeemInfo={UUId:redeem.uuId,RedeemState:redeem.redeemState};
              temp.redeems.push(info);
            })
            if( JSON.stringify(this.productInfo )!= JSON.stringify(temp)){
              this.productInfo=temp;
              this.product = value;
            }
          }
        })
    });
  }

  back(): void {
    this.location.back();
  }

  get totalStock(): number {
    if (this.product == null)
      return 0;
    return this.redeemedUsers.map(redeem => redeem.quantity).reduce((sum, value) => sum + value, 0) + this.product.stock;
  }

  get redeemedUsers(): Redeem[] {
    return this.product ? this.product.redeems.filter(redeem => redeem.redeemState == "Redeemed"|| redeem.redeemState == "Delivered") : [];
  }

  get pendingUsers(): Redeem[] {
    return this.product ? this.product.redeems.filter(redeem => redeem.redeemState == "Pending") : [];
  }

  get user(): Profile | null {
    return this.redeemService.user;
  }
}
